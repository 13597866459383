import {
  computePosition,
  shift,
  limitShift,
  offset,
  flip,
} from '@floating-ui/dom';

import MetaMap from '../libs/metaMap.lib.js';
import { is_touch_enabled } from '../utils/navigator.utils.js';

import Swiper, { Autoplay, EffectFade, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let markers = [
  {
    lat: 53.528881,
    long: -2.579298,
  },
  {
    lat: 34.049246,
    long: -118.294567,
  },
  {
    lat: 19.416088,
    long: -99.229906,
  },
  {
    lat: -39.074644,
    long: -68.022409,
  },
  {
    lat: -32.750323,
    long: 21.313259,
  },
  {
    lat: 8.341953,
    long: 0.184791,
  },
  {
    lat: 51.903613,
    long: 6.951336,
  },
  {
    lat: 57.903174,
    long: 13.635385,
  },
  {
    lat: 43.149094,
    long: 25.244522,
  },
  {
    lat: 57.949845,
    long: 54.003521,
  },
  {
    lat: 25.502785,
    long: 51.170076,
  },
  {
    lat: 24.627045,
    long: 55.039788,
  },
  {
    lat: 21.88189,
    long: 111.571077,
  },
  {
    lat: 36.120128,
    long: 139.421674,
  },
  {
    lat: -8.9936,
    long: 120.77463,
  },
  {
    lat: -33.998027,
    long: 149.789727,
  },
];

window.initMetaMap = function initMetaMap(markers) {
  const mapSection = document.querySelector('.map-section');

  if (mapSection) {
    const mapWrap = mapSection.querySelector('#map');
    const popover = mapSection.querySelector('.map-popover');
    const popoverContent = mapSection.querySelector('.map-popover');
    const popoverPseudo = mapSection.querySelector('.map-popover-pseudo');
    /*const imgPopover = popover.querySelector('.map-popover__img');*/

    const mapSlidesArr = mapSection.querySelectorAll('.map-slider__item');

    const isTab = matchMedia('(max-width: 768px)').matches;

    let radius = 6;

    if (window.innerWidth <= 640) {
      radius = 10;
    }

    new MetaMap('#map', {
      mapPath: 'app/assets/static/map.geo.json',
      width: 1300,
      height: 850,
      isZoomable: false,
      countryStrokeWidth: 0,
      countryStrokeColor: '#ffffff',
      markerStyle: {
        type: 'pin',
        color: '#883EDD',
        width: '7px',
        height: '7px',
        radius: radius,
        strokeWidth: 3,
        shadow: {
          color: 'rgba(136,62,221,0.5)',
          blur: radius + 4,
          radius: radius + 4,
        },
      },
      zoomDefault: 1,
      translateDefault: isTab ? [13.628032, 21.666104] : null,
      markers: markers,
      /*pattern: (defs) => {
        const pattern = defs
          .append('pattern')
          .attr('id', 'pattern')
          .attr('patternUnits', 'userSpaceOnUse')
          .attr('width', 9)
          .attr('height', 9);

        return pattern
          .append('circle')
          .attr('cx', 4.5)
          .attr('cy', 4.5)
          .attr('r', 3)
          .style('fill', '#24365B');
      },
      patternGradient: (defs) => {
        const patternGradient = defs
          .append('linearGradient')
          .attr('id', 'patternGradient')
          .attr('gradientUnits', 'userSpaceOnUse');
        patternGradient.append('stop').attr('stop-color', '#EDAAC0');
        patternGradient
          .append('stop')
          .attr('stop-color', '#B1B3C0')
          .attr('offset', '1');

        return patternGradient;
      },*/
      on: {
        markerClick: ({ data, target }) => {
          const activeMarkers = mapWrap.querySelectorAll('.marker.active');
          activeMarkers.forEach((marker) => marker.classList.remove('active'));

          target.classList.add('active');

          popover.classList.add('active');
          popoverPseudo.classList.add('active');

          /*imgPopover.src = data.info.logo;*/

          updatePosition(target, popover);
          updatePositionPseudo(target, popoverPseudo);
          updateMarkerLines();

          const markersArr = document.querySelectorAll('#map .marker');
          markersArr.forEach((marker, i) => {
            if (marker.classList.contains('active')) {
              popoverContent.innerHTML = mapSlidesArr[i].innerHTML;
            }
          });
        },
        zoom: () => {
          const activeMarkers = mapWrap.querySelectorAll('.marker.active');
          activeMarkers.forEach((marker) => marker.classList.remove('active'));

          popover.classList.remove('active');
          popoverPseudo.classList.remove('active');
          updateMarkerLines();
        },
      },
    });

    window.addEventListener('click', ({ target }) => {
      if (!target.classList.contains('marker') && window.innerWidth >= 641) {
        const activeMarkers = mapWrap.querySelectorAll('.marker.active');
        activeMarkers.forEach((marker) => marker.classList.remove('active'));

        popover.classList.remove('active');
        popoverPseudo.classList.remove('active');
        updateMarkerLines();
      }
    });

    setTimeout(function () {
      function updateMarkerLines() {
        const mapLinesContainer = mapSection.querySelector('.map-decors');
        const mapLine = mapLinesContainer.querySelector('span');
        const markersArr = mapSection.querySelectorAll('.markers .marker');

        mapLinesContainer.innerHTML = '';

        const originalMapLine = mapLine.cloneNode(true);

        mapLine.remove();

        markersArr.forEach((marker) => {
          const markerRect = marker.getBoundingClientRect();
          const mapSectionRect = mapWrap.getBoundingClientRect();

          const top =
            markerRect.top - mapSectionRect.top + mapSection.scrollTop;
          const left =
            markerRect.left - mapSectionRect.left + mapSection.scrollLeft;

          const mapLineClone = originalMapLine.cloneNode(true);

          mapLineClone.style.position = 'absolute';
          mapLineClone.style.top = `${top}px`;
          mapLineClone.style.left = `${left}px`;
          mapLineClone.classList.remove('active-sw');

          mapLinesContainer.appendChild(mapLineClone);
        });

        mapLinesContainer.style.opacity = 1;
      }

      updateMarkerLines();
      window.addEventListener('resize', updateMarkerLines);
    }, 300);

    let mapSwiperWr = document.querySelectorAll('.map-swiper-wr');
    mapSwiperWr.forEach((el) => {
      if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let slides = el.querySelectorAll('.swiper-slide');
        let wrapper = swiperEl.querySelector('.swiper-wrapper');

        let swiper = new Swiper(swiperEl, {
          modules: [Autoplay, Navigation, EffectFade],
          observer: true, //needs to calculate swiper size
          observeParents: true, //needs to calculate swiper size
          spaceBetween: 20,
          slidesPerView: 1,
          threshold: 10,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          autoHeight: true,
          navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
          },
          on: {
            slideChange: function () {
              const markersArr =
                mapSection.querySelectorAll('.markers .marker');
              const mapLinesContainer = mapSection.querySelector('.map-decors');
              const mapLinesArr = mapLinesContainer.querySelectorAll('span');
              markersArr.forEach((marker) =>
                marker.classList.remove('active-sw')
              );
              mapLinesArr.forEach((markerLine) =>
                markerLine.classList.remove('active-sw')
              );
              if (markersArr[swiper.realIndex]) {
                markersArr[swiper.realIndex].classList.add('active-sw');
                mapLinesArr[swiper.realIndex].classList.add('active-sw');
                updatePositionPseudo(
                  markersArr[swiper.realIndex],
                  popoverPseudo
                );
                popoverPseudo.classList.add('active');
                const labelText =
                  slides[swiper.realIndex].querySelector('.map-popover-label');
                const labelPseudo = popoverPseudo.querySelector('span');
                labelPseudo.textContent = labelText.textContent;
              }
            },
          },
          /*autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          },*/
        });

        setTimeout(function () {
          const markersArr = mapSection.querySelectorAll('.markers .marker');
          const mapLinesContainer = mapSection.querySelector('.map-decors');
          const mapLinesArr = mapLinesContainer.querySelectorAll('span');
          markersArr[0].classList.add('active-sw');
          mapLinesArr[0].classList.add('active-sw');

          updatePositionPseudo(markersArr[0], popoverPseudo);
          popoverPseudo.classList.add('active');

          const labelText = slides[0].querySelector('.map-popover-label');
          const labelPseudo = popoverPseudo.querySelector('span');
          labelPseudo.textContent = labelText.textContent;
        }, 350);

        /*swiperObserver(swiper);*/
      }
    });
  }

  function updatePosition(pin, popover) {
    computePosition(pin, popover, {
      placement: 'left-start',
      strategy: 'fixed',
      middleware: [
        flip(),
        shift({
          crossAxis: true,
          limiter: limitShift(),
        }),
        offset(0),
      ],
    }).then(({ x, y }) => {
      Object.assign(popover.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  function updatePositionPseudo(pin, popoverPseudo) {
    computePosition(pin, popoverPseudo, {
      placement: 'left',
      strategy: 'fixed',
      middleware: [
        flip(),
        shift({
          crossAxis: true,
          limiter: limitShift(),
        }),
        offset(10),
      ],
    }).then(({ x, y }) => {
      Object.assign(popoverPseudo.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  function updateMarkerLines() {
    const mapLinesContainer = mapSection.querySelector('.map-decors');
    const mapLinesArr = mapLinesContainer.querySelectorAll('span');
    const markersArr = mapSection.querySelectorAll('.markers .marker');

    markersArr.forEach((marker, index) => {
      if (marker.classList.contains('active')) {
        mapLinesArr[index].classList.add('active');
      } else {
        mapLinesArr[index].classList.remove('active');
      }
    });
  }
};
